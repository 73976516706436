/*
  Project: Fuzionaire
  Author: Xfive
 */

import 'core-js/fn/array/from'

import Menu from './menu'
import TeamModal from './team-modal'
import Lazyload from 'vanilla-lazyload'


new Menu()
new TeamModal()
new Lazyload()
